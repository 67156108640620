.info {
  text-align: start;
}

.info__container {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: rgb(196, 195, 195);
  box-sizing: border-box;
  padding: 50px 70px;
  max-width: 100%;
}

.info__title {
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px solid black;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}

.info__subtitle {
  margin-left: 20px;
  padding: .3em .5em .3em .4em;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.info__text {
  margin-left: 20px;
  padding: .3em .5em .3em .4em;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

body {
  background: #edf2f7;
}

details {
  display: block;
  margin-bottom: .5rem;
}

.info__subtitle::-webkit-details-marker {
  display: none;
}

.info__subtitle::-moz-list-bullet {
  list-style-type: none;
}

.info__subtitle::marker {
  display: none;
}

.info__subtitle {
  padding: .3em .5em .3em .4em;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
}

.info__subtitle~* {
  padding: 0 1em 0 1em;
}

.info__subtitle:focus {
  outline: 0;
}

@media screen and (max-width: 1279px) {}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 1000px) {
  .info__container {
    background-color: rgb(196, 195, 195);
    box-sizing: border-box;
    max-width: 100%;
    padding: 20px 5px;
  }

  .info__title {
    margin-left: 20px;
    width: 90%;
    height: 100%;
    border-bottom: 1px solid #DADADA;
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
    text-align: start;
    margin-bottom: 20px;
  }

  .info__subtitle {
    margin-left: 20px;
    padding: .3em .5em .3em .4em;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
  }

  .info__text {
    margin-left: 20px;
    padding: .3em .5em .3em .4em;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
  }

}
.header{
  background-color: white;
  padding: 30px 70px 0;
}

.header__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  height: 120px;
  margin: auto;
  border-bottom: 1px solid black;
  gap: 10px;
  
}

.header__logo {
  display: flex;
  align-items: center;
  width: 300px;
  height: 100%;
  background-image: url('../../images/nplogo3.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.header__text {
  font-size: 20px;
  font-weight: 500;
  max-width: 35%;
  text-align: center;
}

.header__contact {
  display: flex;
}

.header__links {
  display: flex;
  align-items: center;
}

.header__link {
  width: 36px;
  height: 36px;
  margin: 0 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.header__w {
  background-image: url(../../images/4102606_applications_media_social_whatsapp_icon.svg);
}
.header__vk {
  background-image: url(../../images/vk-1-logo-svgrepo-com.svg);
}
.header__us {
  background-image: url(../../images/fallback_q75.webp);
}

.header__t {
  background-image: url(../../images/telegram-svgrepo-com.svg);
}

.header__tel {
  margin: auto;
  color: black;
  font-size: 20px;
  font-weight: 900;
  list-style: none;
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  .header__contact {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

}

@media screen and (max-width: 1100px) {
  .header{
    background-color: white;
    padding: 10px 0;
  }

  .header__container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    height: 100%;
    margin: auto;
    border-bottom: none;
    gap: 10px;
    
  } 

  .header__logo {
    width: 320px;
    height: 130px;
  }

  .header__text {
    font-size: 20px;
    font-weight: 500;
    max-width: 70%;
    text-align: center;
  }

}

@media screen and (max-width: 500px) {
  .header{
    background-color: white;
    padding: 10px 0;
  }

  .header__container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    height: 100%;
    margin: auto;
    border-bottom: none;
    gap: 10px;
    
  }

  .header__logo {
    width: 250px;
    height: 100px;
    
  }

  .header__text {
    font-size: 16px;
    font-weight: 500;
    max-width: 100%;
    padding: 0 30px 0;
    text-align: center;
  }

  .header__tel {
    margin: auto;
    color: black;
    font-size: 15px;
    font-weight: 900;
    list-style: none;
    text-decoration: none;
  }

  .header__link {
    width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
  }

}
.lightbox-conteiner {
    background-color: rgba(196, 195, 195);
    padding: 20px 0;
    text-align: center;
}

.lightbox-title {
    color: #000;
    margin-bottom: 30px;
}


.lightbox-img_background {
    margin: auto;
    max-width: 80%;
    object-fit: contain;
    border-radius: 15px;
    cursor: pointer;
    height: 90%;
}

.lightbox-img_background__popup {
    display: flex;
    height: 80%;
    margin: 50px auto 0;
    
}

.lightbox__popup {
    padding: 0;
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.swiper-button-prev {
    color: black;
    width: 30px;
    height: 50px;
    font-weight: 900;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid black;
}

.swiper-button-next {
    color: black;
    width: 30px;
    height: 50px;
    font-weight: 900;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid black;
    z-index: 10;
}


.swiper {
    padding: 20px 0;
    height: 100%;
}

.swiper-slide {
    margin: 0;
}

.lightbox__swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #706565;
    width: 90%;
    height: 300px;
    object-fit: cover;
    border-radius: 15px;
}


.swiper-wrapper {
    width: 100%;
    gap: auto;
}

.append-buttons {
    text-align: center;
    margin-top: 20px;
}






@media screen and (max-width: 590px) {


    .lightbox__swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #706565;
        min-width: 280px;
        object-fit: cover;
        border-radius: 15px;
    }

    .lightbox-title {
        font-size: 23px;
    }

}
.steps {
  background-color: white;
  max-width: 100%;
  margin: auto;
}

.steps__title{
  margin: 0;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 23px;
  font-size: 22px;
  font-weight: 500;
}

.steps__container {
  padding: 50px 70px 50px;
  max-width: 100%;
  box-sizing: border-box;
}

.steps__list {
  display: flex;
  justify-content: space-around;
  align-items: start;
}

.steps__item {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.steps__icon{
  display: flex;
  flex-direction: row;
}

.steps__num {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(123, 126, 133);
  width: 50px;
  height: 50px;
  border: 1px solid black;
  border-radius: 50%;
  font-size: 30px;
  color: white;
  z-index: 1;
}

.steps__img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 1px solid black;
  border-radius: 50%;
  font-size: 30px;
  color: white;
  z-index: 2;
  margin-left: -10px;
  margin-top: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.steps__tel{
  font-size: 13px;
  font-weight: 500;
  color: black;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}

.steps__of {
  background-image: url('../../images/oform.jpg');
}

.steps__zam {
  background-image: url('../../images/zamer.jpg');
}

.steps__dog {
  background-image: url('../../images/dog.jpg');
}

.steps__set {
  background-image: url('../../images/set.jpg');
}

.steps__fin {
  background-image: url('../../images/fin.jpg');
}

.steps__text {
  font-size: 14px;
  font-weight: 500;
  color: black;
  width: 80%;
  margin-top: 10px;
  margin-left: 10px;
}


@media screen and (max-width: 1279px) {
  .steps__title{
    margin: 0;
    border-bottom: 1px solid #DADADA;
    padding-bottom: 23px;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }

  .steps__list {
   margin-top: 20px;
  }

}

@media screen and (max-width: 1050px) {
  .steps__img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid black;
    border-radius: 50%;
    font-size: 30px;
    color: white;
    z-index: 2;
    margin-left: -20px;
    margin-top: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .steps__list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: normal;
    padding: 0;
  }

  .steps__item {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  
  .steps__num {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  

  .steps__title{
    margin: 0;
    height: 100%;
    border-bottom: 1px solid #DADADA;
    padding-bottom: 23px;
    font-size: 20px;
    font-weight: 500;
  }
}

@media screen and (max-width: 800px) {

  .steps {
    background-color: white;
    max-width: 80%;
    margin: auto;
  }

  .steps__title{
    font-size: 18px;
    font-weight: 900;
  }
  
  .steps__container {
    padding: 30px 0 30px;
  }
  

}
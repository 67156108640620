.promo {
  background-image: url(../../images/1680535999236.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 100%;
}

.promo__cont {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  justify-content: center;
  align-items: center;
}

.promo__texts {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.promo__title {
  font-size: 32px;
  line-height: 34px;
  font-weight: 900;
  color: white;
padding-left: 10px;
}

.promo__li {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: white;
  text-align: start;
}

.promo__photo {
  margin: 30px;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../../images/diploma.svg);
  transform: scale(-1, 1);
  border: 1px solid gray;
}

.promo__list {
  width: 80%;
}

@media screen and (max-width: 1023px) {
  .promo__photo {
    width: 300px;
    height: 300px;

  }
}

@media screen and (max-width: 750px) {

  .promo__texts {
    max-width: 90%;
  }

  .promo__photo {
    display: none;
  }

  .promo__title {
    font-size: 28px;
    line-height: 28px;
  }
}

@media screen and (max-width: 560px) {
  .promo {
    background-size: cover;
    margin: 0 auto 0;

  }

  .promo__cont {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 250px;
  }

  .promo__title {
    text-align: center;
    font-size: 22px;
    line-height: 24px;
    font-weight: 900;
    width: 100%;
  }


  .promo__li {
    display: none;
  }

  .promo__photo {
    display: none;
  }

}
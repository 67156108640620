.main {
  margin-top: 30px;
  width: 100%;
  background-color: white;
}

.section__title {
  margin: 0;
  height: 27px;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 23px;
  font-size: 22px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {

  .main {
    margin-top: 0;
  }

  .section__title {
    height: 22px;
    padding-bottom: 28px;
    font-size: 18px;
  }
}
.tools {
  max-width: 100%;
  background-color: white;
  margin: auto;
}

.tools__title {
  margin: 0;
  height: 27px;
  border-bottom: 1px solid #DADADA;
  padding-bottom: 43px;
  font-size: 22px;
  font-weight: 500;
}

.tools__container {
  padding: 50px 70px 50px;
  max-width: 100%;
  box-sizing: border-box;
  
}

.tools__list {
  display: flex;
  padding: 0;
  justify-content: space-around;
  align-items: start;
  padding-top: 20px;
}

.tools__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tools__img {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 90%;
  width: 200px;
  height: 200px;
  border: 1.3px solid black;
  border-radius: 50%;
}

.tools__gaz {
  background-image: url(../../images/gaz.jpeg);
  background-position: center;
}

.tools__perf {
  background-image: url(../../images/perf.webp);
  background-position: bottom;
}

.tools__gvozd {
  background-image: url(../../images/gvozd.jpg);
  background-position: center;
}

.tools__lazer {
  background-image: url(../../images/lazer.webp);
  background-position: center;
}

.tools__text {
  font-size: 14px;
  font-weight: 500;
  color: black;
  width: 200px;
  text-align: center;
}


@media screen and (max-width: 1279px) {
  .tools__img {
    background-repeat: no-repeat;
    background-size: 90%;
    width: 150px;
    height: 150px;
  }

}

@media screen and (max-width: 901px) {

  .tools__title {
    font-size: 20px;
    font-weight: 500;
  }

  .tools__list {
    padding: 0;
    display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 10px;
  width: 100%;
  }

  .tools__img {
    background-repeat: no-repeat;
    background-size: 90%;
    width: 150px;
    height: 150px;
    border: 1px solid black;
    border-radius: 50%;

  }
}

@media screen and (max-width: 550px) {
  .tools {
    max-width: 90%;
  }
  
  .tools__title {
    margin: 0;
    height: 100%;
    border-bottom: 1px solid #DADADA;
    padding-bottom: 23px;
    font-size: 15px;
    font-weight: 500;
  }
  
  .tools__container {
    padding: 30px 0 30px;
    max-width: 100%;
    box-sizing: border-box;
    
  }
  
  .tools__list {
    padding: 0;
    display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 10px;
  width: 100%;
  }

  .tools__img {
    background-repeat: no-repeat;
    background-size: 90%;
    width: 100px;
    height: 100px;
    border: 1px solid black;
    border-radius: 50%;

  }
  
  .tools__text {
    font-size: 12px;
    color: black;
    width: 100%;
    text-align: center;
  }
  }


.footer {
  font-size: 13px;

}

.footer__container {
  background-color: white;
  color: black;
  box-sizing: border-box;
  margin: auto;
  max-width: 90%;
  padding-top: 40px;
}

.footer__title {
  margin: 0;
  padding: 0 0 20px;
  
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.footer__subtitle {
  margin: 0;
  padding: 0 0 20px;
  
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.footer__block {
  border-top: 1px solid black;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.footer__year {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.footer__links {
  display: flex;
  align-items: center;
}

.footer__link {
  text-decoration: none;
  color: white;
  font-size: 14px;
  line-height: 18px;
  transition: opacity 0.3s;
}

.footer__item:first-of-type {
  margin: 0;
}

.footer__link {
  transition: opacity 0.3s;
  text-decoration: none;
  font-size: 13px;
  font-weight: 400;
}

.footer__link:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1050px) {
  .footer__block {
    border-top: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }
}

@media screen and (max-width: 321px) {
  .footer {
    font-size: 13px;
  }
  
  .footer__container {
    background-color: white;
    color: black;
    box-sizing: border-box;
    margin: auto;
    max-width: 90%;
    padding-top: 40px;
  }
  
  .footer__title {
    margin: 0;
    padding: 0 0 20px;
    
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }
  
  .footer__subtitle {
    margin: 0;
    padding: 0 0 20px;
    
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }
  
  .footer__block {
    border-top: 1px solid black;
    margin: 20px 0 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .footer__year {
    margin-top: 20px;
    font-size: 13px;
    font-weight: 400;
  }
  
  .footer__links {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .footer__link {
    text-decoration: none;
    color: white;
    font-size: 14px;
    line-height: 18px;
    transition: opacity 0.3s;
  }
  
  .footer__item:first-of-type {
    margin: 0;
  }
  
  .footer__links {
    margin-bottom: 10px;
  }
  
  .footer__link:hover {
    opacity: 0.6;
  }
}